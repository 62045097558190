import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WEATHER_API_URL } from '@constants/Urls.const';
import { FileService } from '@Services/file/file.service';
import {
  CreateProjectPayload,
  EditProjectPayload,
  ProjectDetailsResponse,
} from '@shared/interfaces';
import { SecretKeys } from '@shared/interfaces/common.interface';
import { WeatherData } from '@shared/interfaces/weather.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private readonly http: HttpClient,
    private readonly fileService: FileService,
  ) {}

  /**
   * Creates a new project.
   * @param createProjectPayload - The payload for creating a project.
   * @returns An Observable of ProjectDetailsResponse type.
   */
  createProject(createProjectPayload: CreateProjectPayload): Observable<ProjectDetailsResponse> {
    return this.http.post(
      `assetmanagement/projects/`,
      createProjectPayload,
    ) as Observable<ProjectDetailsResponse>;
  }

  /**
   * Retrieves the details of a project.
   * @param id - The ID of the project.
   * @param silently - A boolean value indicating whether to load the project silently. default is false.
   * @returns An Observable of type ProjectDetailsResponse.
   */
  getProjectDetails(id: string, silently = false): Observable<ProjectDetailsResponse> {
    return this.http.get(`assetmanagement/projects/${id}/`, {
      headers: { 'hide-loader': silently ? '1' : '' },
    }) as Observable<ProjectDetailsResponse>;
  }

  /**
   * Updates the details of a project.
   * @param id - The ID of the project.
   * @param data - The payload for updating the project details.
   * @returns An Observable of type ProjectDetailsResponse.
   */
  updateProjectDetails(id: string, data: EditProjectPayload): Observable<ProjectDetailsResponse> {
    return this.http.put(
      `assetmanagement/projects/${id}/`,
      data,
    ) as Observable<ProjectDetailsResponse>;
  }

  /**
   * Deletes a project.
   * @param id - The ID of the project to be deleted.
   * @param reason - The reason for deleting the project.
   * @returns An Observable of unknown type.
   */
  deleteProject(id: string, reason: string): Observable<unknown> {
    return this.http.delete(
      `assetmanagement/projects/${id}/?reason=${reason}`,
    ) as Observable<unknown>;
  }

  /**
   * Retrieves weather data for a city.
   * @param city - The name of the city.
   * @param appId - The API key for accessing weather data.
   * @returns An Observable of type WeatherData.
   */
  getWeatherData(lat: string, long: string, appId: string): Observable<WeatherData> {
    return this.http.get(
      `${WEATHER_API_URL}lat=${lat}&lon=${long}&appid=${appId}&units=metric`,
    ) as Observable<WeatherData>;
  }

  /**
   * Retrieves secret keys.
   * @returns An Observable of type SecretKeys.
   */
  getSecretKeys(): Observable<SecretKeys> {
    const payLoad = {
      weather: true,
      location: true,
    };
    return this.http.post(`api/secrets/`, payLoad) as Observable<SecretKeys>;
  }

  /**
   * Archives a project.
   * @param id - The ID of the project to be archived.
   * @param payload - The payload for archiving the project.
   * @returns An Observable of type ProjectDetailsResponse.
   */
  archiveProject(id: string, payload: { reason: string }): Observable<ProjectDetailsResponse> {
    return this.http.put(
      `assetmanagement/projects/${id}/archive/`,
      payload,
    ) as Observable<ProjectDetailsResponse>;
  }

  /**
   * Downloads project details as a CSV file.
   * @param projectId - The ID of the project to download details for.
   * @returns A Promise that resolves when the download is complete.
   */
  downloadProjectDetailsCSV(projectId: string): Promise<void> {
    return this.fileService.downloadFile(
      `assetmanagement/projects/${projectId}/download/`,
      `project_${projectId}.csv`,
    );
  }

  /**
   * import projects by xlsx
   * @param blob
   * @param filename
   * @returns
   */
  importProjectsByXLSX(blob: Blob, filename: string): Observable<unknown> {
    const formData: FormData = new FormData();
    formData.append('file', blob, filename);

    return this.http.post(`assetmanagement/upload-project/`, formData) as Observable<unknown>;
  }
}
