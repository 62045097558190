import { Injectable } from '@angular/core';
import { ProjectDetailsResponse } from '@shared/interfaces';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ProjectService } from '../project/project.service';

export interface IStateProject {
  data: ProjectDetailsResponse | null;
  error: Error | null;
}

@Injectable({
  providedIn: 'root',
})
export class SelectedProjectDataService {
  constructor(private readonly projectService: ProjectService) {}

  private dataSubject = new BehaviorSubject<IStateProject>({
    data: null,
    error: null,
  });

  /**
   * Get the selected project
   * @returns
   */
  getSelectedProject(): Observable<IStateProject> {
    return this.dataSubject.asObservable();
  }

  /**
   * Set the selected project
   * @param project
   */
  setSelectedProject(project: ProjectDetailsResponse | null) {
    return this.dataSubject.next({
      error: null,
      data: project,
    });
  }

  /**
   * Load the project data silently
   * @param projectId
   */
  loadProjectSilently(projectId: string) {
    const observer = this.projectService
      //passed true to get data silently without showing loader
      .getProjectDetails(projectId, true)
      .pipe(
        tap({
          error: (error) => {
            this.dataSubject.next({ data: null, error });
            observer.unsubscribe();
          },
          next: (projectData) => {
            this.dataSubject.next({ data: projectData, error: null });
            observer.unsubscribe();
          },
        }),
      )
      .subscribe();
  }

  /**
   * Reset the data subject
   */
  reset() {
    this.dataSubject.next({ data: null, error: null });
  }
}
